import React, { FC, useState, useRef, useEffect, Fragment } from 'react';
import { CommentList, Tag as CommentTag } from '@tencent/coral-mobile-comment-list';
import {
  getApiV3PostsReplies, getApiV3PostsRepliesReplies, getApiV3PostsRepliesById,
  post_api_posts_replies_liked as postApiPostsRepliesLiked,
} from 'components/api';
import Tag from 'components/tag';
import { href_profile as hrefProfile } from 'components/href-helper';
import Route from 'components/util/route';
import formatTime from 'components/util/formatTime';
import { unionBy } from 'lodash-es';
import pxToTaroRem from 'components/util/pxToTaroRem';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';

import './index.less';
// TODO 由于样式覆盖，需要单独引入组件，下一期优化改掉
import '@tencent/coral-mobile-comment-list/dist/style/components/comment-list.less';
import '@tencent/coral-mobile-comment-list/dist/style/components/comment.less';
import '@tencent/coral-mobile-comment-list/dist/style/components/like.less';
import '@tencent/coral-mobile-comment-list/dist/style/components/loading.less';
import '@tencent/coral-mobile-comment-list/dist/style/components/list.less';
import '@tencent/coral-mobile-comment-list/dist/style/components/spinner.less';
import '@tencent/coral-mobile-comment-list/dist/style/components/tag.less';

export enum COMMENT_ACTIONS {
  /** 默认 */
  DEFAULT,
  /** 删除 */
  DELETED,
  /** 加黑 */
  BLOCK,
  /** 创建 */
  CREATE,
}
interface PostCommentListProps {
  /** 产品ID */
  productId: string;
  /** 帖子ID */
  postId: string;
  /** 作者ID */
  authorId?: string | number;
  /** 评论被点击 */
  onClickItem: (id, name) => void
  /** 更多被点击 */
  onClickMore: (comment) => void
  /** 部分特殊操作需要更新评论状态*/
  action?: COMMENT_ACTIONS
  /** 需要更新的操作 */
  actionComentId: string
  /** 是否允许非管理员回复链接 */
  renderLink: string
}

const PostCommentList: FC<PostCommentListProps> = ({
  productId, postId, authorId, onClickItem,
  onClickMore, action, actionComentId, renderLink,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const nextPageId = useRef('');

  // 响应 增、删、改评论
  useEffect(() => {
    if (!actionComentId) return;

    // 拉黑 删除都是过滤掉该条评论
    if (action === COMMENT_ACTIONS.BLOCK || action === COMMENT_ACTIONS.DELETED) {
      setData((data) => {
        // 先过滤评论ID为当前ID的
        const newData = filterById(data, actionComentId);

        // 如果已经过滤掉则直接返回，否则删的是子评论再过滤一遍子评论
        if (newData.length !== data.length) {
          setTotal(total => total - 1);
          return newData;
        }

        return newData.map((item) => {
          const replies = filterById(item.replies, actionComentId);
          return {
            ...item,
            replies,
            replyCount: item.replies.length === replies.length ? item.replyCount : item.replyCount - 1,
          };
        });
      });
    }


    // 新增评论或者子评论
    if (action === COMMENT_ACTIONS.CREATE) {
      getApiV3PostsRepliesById({ productId, postId, commentId: actionComentId })
        .then((res) => {
          // 如果是子评论则加到相应父级评论中 这里没有根节点 ID 后端用“0”表示
          if (res.data.rootCommentID !== '0') {
            // eslint-disable-next-line max-nested-callbacks
            setData(data => data.map((comment) => {
              if (comment.id === res.data.rootCommentID) {
                return {
                  ...comment,
                  replies: unionBy([res.data], comment.replies, 'id'),
                  replyCount: comment.replyCount + 1,
                };
              }

              return comment;
            }));

            return;
          }

          setData(data => unionBy([res.data], data, 'id'));
          setTotal(total => total + 1);
        });
    }
  }, [action, actionComentId, postId, productId]);

  const filterById = (arr: any[], id) => arr.filter(item => item.id !== id);

  // 加载更多一级评论
  const loadMoreComments = async ({ page }) => {
    const res: any = await getApiV3PostsReplies({ nextPageId: nextPageId.current, productId, postId, perPage: 10 });

    // NOTE 根据目前后端接口设计目前只有第一页有total数据
    if (page === 1) {
      setTotal(res.pagination.total);
    }
    setData(data => data.concat(res.data));
    setHasMore(res.pagination.has_more_pages);
    nextPageId.current = res.pagination.next_page_id;
    return res;
  };

  // 格式化内容， 增加表情渲染
  const formatContent = data => data.map(comment => ({
    ...comment,
    content: <Tag.span
      className="comment-list__content"
      dangerouslySetInnerHTML={dangerouslySetInnerHTML(comment.content, productId, 'p', renderLink === 'on' || comment.fromUser.isAdmin)}
    />,
    // TODO 需要支持缩略图
    images: comment.images.map(image => image.thumb_url),
    previewImages: comment.images.map(image => image.original_url),
    // NOTE 前端胶水层， 之后接口有机会上微服务就下掉
    toUser: { ...comment.toUser, guid: comment.toUser.id },
    fromUser: { ...comment.fromUser, guid: comment.fromUser.id },
    replies: formatContent(comment.replies),
  }));

  // 加载更多子评论
  const loadMoreReplies = async ({ commentId, replyId }) => {
    const res: any = await getApiV3PostsRepliesReplies({
      nextPageId: replyId, productId, postId, perPage: 10, commentId,
    });

    setData(data => data.map((comment) => {
      if (comment.id !== commentId) {
        return comment;
      }

      return {
        ...comment,
        replies: unionBy(comment.replies, res.data, 'id'),
      };
    }));
    return res;
  };


  const handleUserClick = ({ comment }) => {
    Route.push(hrefProfile(comment.fromUser.id));
  };

  const handleCommentClick = ({ comment }) => {
    onClickItem(comment.id, comment.fromUser?.userName);
  };

  const updateLikeAsync = (commentId, hasLiked) => postApiPostsRepliesLiked({
    productId,
    postId,
    replyId: commentId,
    val: hasLiked ? 'off' : 'on',
  });

  const handleShowMoreOtionClick = ({ comment }) => {
    onClickMore(comment);
  };

  // 渲染标签
  const renderCommentExtraUserName = (isRootComment: boolean) => ({ comment }) => {
    const user = isRootComment ? comment.fromUser : comment.toUser;

    return getTag({ isAdmin: user.isAdmin, isAuthorId: user.id === authorId });
  };

  const getTag = ({ isAdmin, isAuthorId }) => {
    const ADMIN_TYPE = 1;
    const AUTHOR_TYPE = 0;
    if (isAdmin) {
      return <CommentTag type={ADMIN_TYPE} title="官方" />;
    }

    if (isAuthorId) {
      return <CommentTag type={AUTHOR_TYPE} title="作者" />;
    }
    return null;
  };

  const renderCustomInfo = comment => (
    <Fragment>
      {comment.fromUser.groupName && <Tag.p isText>{`${comment.fromUser.groupName}/${comment.fromUser.groupDetailName}`}</Tag.p>}
      <Tag.p isText>{formatTime(comment.createdTimestamp)}</Tag.p>
    </Fragment>
  );

  return (
    <CommentList
      data={formatContent(data)}
      activeTab={0}
      loadMoreComments={loadMoreComments}
      maxSingleImageSize={pxToTaroRem(130)}
      loadMoreReplies={loadMoreReplies}
      hasMore={hasMore}
      total={total}
      renderInfo={renderCustomInfo}
      updateLikeAsync={updateLikeAsync}
      onUserNameClick={handleUserClick}
      onContentClick={handleCommentClick}
      onAvatarClick={handleUserClick}
      onShowMoreOtionClick={handleShowMoreOtionClick}
      renderUserNameExtra={renderCommentExtraUserName(true)}
      renderReplyUserNameExtra={renderCommentExtraUserName(false)}
    />
  );
};



export { PostCommentList };
