import React from 'react';
import Tag from 'components/tag';

import './style.less';
export const FooterContainer = (props) => {

    return (
        <Tag.div className="footer_container">
            <Tag.div className="footer_container_btn">
                {props.button}
            </Tag.div>
            <Tag.div className="footer_container_bar">
                {props.bar}
            </Tag.div>
        </Tag.div>
    );
};