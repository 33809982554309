import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'components/user';
import { ImageMobile } from 'components/comment-image';
import Tag from 'components/tag';
import { DescPara } from 'components/post-item/desc-para.mobile';
import { postsTime, getIWantContent, getProductId, getContentId } from 'components/util';
import { href_profile as hrefProfile } from 'components/href-helper';
import { TAG_COLOR } from 'components/tag-show/constants';
import Topic from 'components/topic/topic-container';
import goodIssue from 'components/assets-image/common-icon/good_issue.png';
import './style.less';

class WantDetail extends React.Component {
  constructor(props) {
    super(props);
    this.productId = getProductId();
    this.postId = getContentId();
  }
  getLikeList() {
    const { like_list: likeList } = this.props;
    if (!likeList) {
      return '';
    }
    const str = likeList.join('、');
    return str;
  }
  render() {
    const {
      content: propsContent,
      images,
      tags,
      showTopic,
      is_spam: isSpan,
      is_notice: isNotice,
      is_good: isGood,
      is_hidden: isHidden,
      is_top: isTop,
      avatar_url: avatarUrl,
      nick_name: nickname,
      like_count: likeCount,
      user_id: userId,
      created_at_timestamp: time,
      renderLink,
      is_admin,
    } = this.props;
    const { title, content, solution } = getIWantContent(propsContent);
    return (
      <Tag.div className="want-detail-card">
        <Tag.div className="want-detail-card__info">
          <Tag.a href={hrefProfile(userId)} >
            <Avatar
              className="want-detail-card__avatar-img item_user"
              src={avatarUrl}
            />
          </Tag.a>
          <Tag.div className="want-detail-card__info-detail">
            <Tag.div className="want-detail-card__info-left">
              <Tag.a
                href={hrefProfile(userId)}
                className="want-detail-card__name auto-focus"
                onClick={event => event.stopPropagation()}
              >
                {nickname}
              </Tag.a>
            </Tag.div>
            <Tag.div className="want-detail-card__info-right">
              {tags && tags.map((item, i) => (
                <Tag.span className="want-detail-card__tag" key={`${i}`}
                  style={{
                    backgroundColor: TAG_COLOR.find(i => i.color === item.setting.color).bg,
                    color: TAG_COLOR.find(i => i.color === item.setting.color).font,
                    border: 'none',
                  }}>{item.name}</Tag.span>
              ))}
              {isSpan && <Tag.span className="want-detail-card__tag">黑名单</Tag.span>}
              {isNotice && <Tag.span className="want-detail-card__tag">公告</Tag.span>}
              {isGood && <Tag.span className="want-detail-card__tag want-detail-card__tag-good">
                <Tag.img className="label_good__icon" src={goodIssue} alt="好问题" />
              </Tag.span>}
              {isHidden && <Tag.span className="want-detail-card__tag">隐藏</Tag.span>}
              {isTop && <Tag.span className="want-detail-card__tag">置顶</Tag.span>}
            </Tag.div>
            <Tag.p className="want-detail-card__time">{postsTime(time)}</Tag.p>
          </Tag.div>
        </Tag.div>
        {!!likeCount && (
          <Tag.div className="want-detail-card__like-list">
            <Tag.span isText className="want-detail-card__name-list">{this.getLikeList()}</Tag.span>
            <Tag.span isText>{likeCount > 1 ? `等${likeCount}人` : ''}所见略同</Tag.span>
          </Tag.div>
        )}
        {showTopic && <Tag.div className="want-detail-card__topic-title">
          {<Topic productId={this.productId} postId={this.postId} isProduct />}
        </Tag.div>}
        <Tag.div className="want-detail-card__main">
          <Tag.div className="want-detail-card__title">我想要</Tag.div>
          {/* eslint-disable-next-line camelcase */}
          <Tag.div className="want-detail-card__title-main"><DescPara desc={title} renderLink={renderLink === 'on' || is_admin} /></Tag.div>
          <Tag.p className="want-detail-card__content">
            <Tag.span className="expression-item" isText aria-hidden>😤&nbsp;</Tag.span>
            目前痛点：
          </Tag.p>
          {/* eslint-disable-next-line camelcase */}
          <DescPara desc={content} className="want-detail-card__content-main" renderLink={renderLink === 'on' || is_admin} />
          <Tag.p className="want-detail-card__solution">
            <Tag.span className="expression-item" isText aria-hidden>💡&nbsp;</Tag.span>
            我的想法：
          </Tag.p>
          {/* eslint-disable-next-line camelcase */}
          <DescPara desc={solution} className="want-detail-card__solution-main" renderLink={renderLink === 'on' || is_admin} />
          {
            (Boolean(images.length)) && (
              <Tag.div className="want-detail-card__images">
                <ImageMobile imgList={images} />
              </Tag.div>
            )
          }
        </Tag.div>
      </Tag.div>
    );
  }
}
WantDetail.propTypes = {
  tags: PropTypes.array,
  is_spam: PropTypes.bool,
  is_notice: PropTypes.bool,
  is_good: PropTypes.bool,
  is_hidden: PropTypes.bool,
  is_top: PropTypes.bool,
  like_count: PropTypes.number,
  content: PropTypes.string,
  userName: PropTypes.string,
  id: PropTypes.number,
  showTopic: PropTypes.bool,
  images: PropTypes.array,
  replies: PropTypes.array,
  like_list: PropTypes.array,
  reply_count: PropTypes.number,
  likeCount: PropTypes.number,
  solution: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  nick_name: PropTypes.string,
  user_id: PropTypes.number,
  created_at_timestamp: PropTypes.number,
  avatar_url: PropTypes.string,
  is_like: PropTypes.bool,
  checkLogin: PropTypes.func,
  renderLink: PropTypes.string,
  is_admin: PropTypes.bool,
};

export default WantDetail;
