import React from 'react';
import PropTypes from 'prop-types';
import Tag from 'components/tag';
import { ariaRole } from 'components/util/aria';
import { TcIconEmoji } from 'components/IconSystem';
import Icon from 'components/icon';

import './style.less';
export const FooterEnter = (props) => {
    const cls = 'footer-enter';
    return (
        <Tag.div needAuth className={cls} >
            <Tag.div
                className={`${cls}__note`}
                onClick={props.showMessageBar}
                {...ariaRole('button')}
                aria-label="发表评论"
            >
                <Icon className={`${cls}__pen`} type="pen-gray" />
                {props.is_locked
                    ? <Tag.span className={`${cls}__notice`} isText>已关闭评论</Tag.span>
                    : <Tag.span className={`${cls}__notice`} isText>快来说两句</Tag.span>
                }
            </Tag.div>

            <Tag.div
                className={`${cls}__trigger`}
                {...ariaRole('button')}
                aria-label="表情"
                onClick={event => {
                    event.stopPropagation();
                    props.showMessageEmoji(true);
                }}>
                <TcIconEmoji className="tcIcon tcIcon_emoji" />
            </Tag.div>
        </Tag.div>
    );
};

FooterEnter.propTypes = {
    showMessageBar: PropTypes.func,
    showMessageEmoji: PropTypes.func,
    is_locked: PropTypes.bool,
};
FooterEnter.defaultProps = {
    showMessageBar: () => {
 console.log('showMessageBar未绑定');
},
    showMessageEmoji: () => {
 console.log('showMessageEmoji未绑定');
},
    is_locked: false
};
