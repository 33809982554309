// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { getJson, deleteJson, postJSON, postFormData } from 'components/api/request';
import { ApiRequest, ProductsReqBase, ResponseBase } from 'components/api/types';

interface GetApiV3PostsRepliesReq extends ProductsReqBase {
  /** 帖子 */
  postId: string | number;
  /** 每页记录数 */
  perPage?: number
  /** 下一页ID */
  nextPageId?: string
}
interface GetApiV3PostsRepliesRepliesReq extends ProductsReqBase {
  /** 帖子 */
  postId: string | number;
  /** 每页记录数 */
  perPage?: number
  /** 评论的ID */
  commentId: string
  /** 下一页ID */
  nextPageId?: string
}
interface GetApiV3PostsRepliesByIdReq extends ProductsReqBase {
  /** 帖子 */
  postId: string | number;
  /** 评论的ID */
  commentId: string
}

export interface City {
}

export interface FromUser {
  guid: string;
  userName: string;
  avatarUrl: string;
  id: number;
}

export interface ToUser {
  guid: string;
  userName: string;
  avatarUrl: string;
  id: number;
}

export interface TargetEntity {
}

export interface Image {
  thumb_url: string;
  large_url: string;
  original_url: string;
}

export interface Reply {
  id: string;
  content: string;
  city: City;
  createdTimestamp: number;
  fromUser: FromUser;
  replyID: string;
  rootCommentID: string;
  likeCount: number;
  replyCount: number;
  replies: Reply[];
  hasLiked: boolean;
  toUser: ToUser;
  isDeleted: boolean;
  showType: string;
  targetEntity: TargetEntity;
  images: Image[];
  isAdmin: boolean;
  isHidden: boolean;
  userGroupName: string;
  userGroupDetailName: string;
}

/** 反馈的评论-第一级列表-获取 */
export const getApiV3PostsReplies: ApiRequest<
  GetApiV3PostsRepliesReq,
  ResponseBase<Reply[]>
> = ({ productId, postId, nextPageId, perPage }) => getJson({
  url: `/api/v3/${productId}/posts/${postId}/replies`,
  data: { per_page: perPage, next_page_id: nextPageId },
});

/** 是的，你没看错就是RepliesReplies, 用来获取评论的评论 */
export const getApiV3PostsRepliesReplies: ApiRequest<
  GetApiV3PostsRepliesRepliesReq,
  ResponseBase<Reply[]>
> = ({ productId, postId, nextPageId, perPage, commentId }) => getJson({
  url: `/api/v3/${productId}/posts/${postId}/replies/${commentId}/replies`,
  data: { per_page: perPage, next_page_id: nextPageId },
});


/** 反馈的评论-根据ID-获取详情 */
export const getApiV3PostsRepliesById: ApiRequest<
  GetApiV3PostsRepliesByIdReq,
  ResponseBase<Reply>
> = ({ productId, postId, commentId }) => getJson({
  url: `/api/v3/${productId}/posts/${postId}/replies/${commentId}`,
});

interface PostApiV3PostModificationReq extends ProductsReqBase {
  postId: string;
  type: number;
  version_date: number;
  version: string;
  SDK_notification: boolean;
  thank_post_id_list: string[];
  // download_link 存post_link
  download_link: string;
  thank_vote_id_list: string[];
  content: string;
}
/** 二次提交更新日志 */
export const postApiV3PostModification: ApiRequest<
  PostApiV3PostModificationReq,
  ResponseBase<Reply>
> = ({
  productId,
  postId,
  type,
  SDK_notification,
  version_date,
  version,
  thank_post_id_list,
  download_link,
  thank_vote_id_list,
  content,
}) => postJSON({
  url: `/api/v3/${productId}/posts/${postId}/modification`,
  data: {
    type,
    version_date,
    version,
    SDK_notification,
    thank_post_id_list,

    download_link,
    thank_vote_id_list,
    content,
  },
});
