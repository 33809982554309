/* eslint complexity: ["error", 25]*/
import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import Sentry from 'components/sentry';
import Tag from 'components/tag';
import BasePage from 'components/base-page-mobile';
import { ariaRole } from 'components/util/aria';
import {
  verificationCode,
  importVercodeScript,
  getProductId,
  getContentId,
  localStorage,
} from 'components/util';
import Icon from 'components/icon';
import SupportBtn, { TYPE as SUPPORT_BTN_TYPE } from 'components/ui/common/support-btn';
import { TcIconReward, TcIconMore } from 'components/IconSystem';
import {
  QQ_PRODUCT_ID,
  QQ_ANDROID_PRODUCT_ID,
  QQ_IOS_PRODUCT_ID,
  POST_TYPE,
} from 'components/constants/app';
import { MessageBar } from 'components/user-enter/message-bar';
import { BtnPublic } from 'components/user-enter/btn/btn-public';
import { TippedModal } from 'components/tipped';
import { isMoreThenMicroMessenger5 } from 'components/tipped/common';
import { FooterEnter } from 'components/footer-enter';
import { MoreOperations } from 'components/more-operations';
import { TagMobileChoose } from 'components/tag-mobile-choose';
import {
  get_api_posts as getApiPosts,
  get_api_tip as getApiTip,
  post_api_tip as postApiTip,
  post_api_tip_batch as postApiTipBatch,
  post_api_posts_upload_images as postApiPostsUploadImages,
  post_api_posts_replies as postApiPostsReplies,
  post_api_replies as postApiReplies,
  post_api_v2_posts_replies as postApiV2PostsReplies,
  post_api_abuse_reports as postApiAbuseReports,
  post_api_posts_liked as postApiPostsLiked,
  post_api_v2_posts as postApiV2Posts,
  delete_api_posts_replies as deleteApiPostsReplies,
  delete_api_posts as deleteApiPosts,
  postListTagsAllGet,
  getProductsTagsList,
  getApiV3RoadmapSettingStatus,
  post_api_liked as postApiLiked,
} from 'components/api';
import { ErrorBoundary } from 'components/error-boundary';
import Notification from 'components/notification';
import { FooterContainer } from 'components/footer-container';
import { GoHome } from 'components/go-home';
import { href_home as hrefHome, getUrlParam } from 'components/href-helper';
import { fromEntries } from 'components/util/new-attribute';
import { FeedContent } from './common/feed-content';
import WantDetailCard from './common/want-detail-card';
import { GlobalToast } from 'components/global-toast';
import 'components/css/mobile/reset.less';
import './style.less';
import loginChecker from 'components/util/login-checker';
import { PostCommentList, COMMENT_ACTIONS } from './common/comment-list';

class App extends Component {
  constructor(props) {
    super(props);

    this.onClickLikeBtn = this.onClickLikeBtn.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.productId = getProductId();
    this.postId = getContentId();
    this.communityId = getUrlParam('community_id');

    this.dataFeteching = false;

    this.dynamicImportVercodeScript = null;

    this.notice = createRef();

    this.state = {
      // 产品信息
      productData: {},
      // 主帖数据
      postData: {},
      // 用户信息
      sessionUser: {},
      // 打赏数据
      tipBatch: [],
      // 点赞数据
      liked_list: {},
      // 是否显示更多操作
      showmore: false,
      // 是否显示标签操作
      showtag: false,
      more_postData: {},
      // 是否显示打赏入口
      can_be_tipped: false,
      // 是否显示打赏弹窗
      show_tipped: false,
      // 是否显示输入键盘，0 不显示，1 显示，2 显示自定义表情键盘
      is_active_input: 0,
      reply_name: '',
      // 当前操作帖子、评论 id
      action_id: this.postId,
      // 全局提示信息
      toast: '',
      error_info: false,
      // 回复后会关闭隐藏的状态
      checked_disabled_hidden: this.initStateForCheckedDisabledHidden(),
      // 全部标签
      tagArrList: [],
      // 话题设置开关
      showTopic: true,
      // 评论相关操作类型
      commentActionType: COMMENT_ACTIONS.DEFAULT,
      // 评论相关操作ID
      commentActionId: '',
    };

    this.zanShang = this.zanShang.bind(this);
    this.showMessageBar = this.showMessageBar.bind(this);
    this.handlePostUpdate = this.handlePostUpdate.bind(this);
    this.toggleDisabledHidden = this.toggleDisabledHidden.bind(this);
  }

  componentDidMount() {
    /** 数据初始化 */
    this.initial();
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  setProductInfo = (productInfo) => {
    this.setState({ productData: productInfo });
  };

  setUserInfo = (userInfo) => {
    this.setState({
      sessionUser: userInfo,
    });
  };

  initial() {
    const { productId, postId } = this;

    // 拉取主帖信息
    getApiPosts({ productId, postId })
      .then(async (res) => {
        const respData = await this.postListTagsAllGetApi(res.data);
        this.setState({
          postData: respData,
        });
      })
      .catch((error) => {
        if (error.message === '找不到贴子') {
          this.setState({ error_info: ErrorBoundary.ERROR_TYPES.NOT_FOUND });
        }

        if (error.message === '没有权限') {
          // NOTE: 由于不主动登录后 没权限可能是由于没登录，这里帮用户登录一次
          loginChecker
            .check()
            .then(() => {
              // 登录了仍然没权限则设置成无权限页面
              this.setState({
                error_info: ErrorBoundary.ERROR_TYPES.FORBIDDEN,
              });
            })
            .catch(() => {
              // 登录失败也设置成无权限页面
              this.setState({
                error_info: ErrorBoundary.ERROR_TYPES.FORBIDDEN,
              });
            });
        }
      });

    // 拉取是否支持打赏
    getApiTip({ productId, postId }).then((res) => {
      this.setState({
        can_be_tipped: res.data.can_be_tipped,
      });
    });

    // 获取赞赏的人数和次数
    postApiTipBatch({ productId, postId }).then((res) => {
      const tipBatch = res.data[postId].data;

      this.setState({
        tipBatch,
      });
    });

    // 获取产品的话题设置
    getApiV3RoadmapSettingStatus({ productId }).then((res) => {
      if (res.status === 0) {
        this.setState({ showTopic: res.data?.status === 'on' });
      }
    });
    // 拉取主贴点赞数据
    postApiLiked({ productId, object_list: [postId] })
      .then((resp) => {
        this.setState(preState => ({
          liked_list: Object.assign({}, preState.liked_list, resp.data),
        }));
      });
  }

  // 获取标签选中信息
  postListTagsAllGetApi(resData) {
    return new Promise((resolve) => {
      postListTagsAllGet(this.productId, 'post', [resData.id])
        .then((contentTagList) => {
          if (!isEmpty(contentTagList.data)) {
            getProductsTagsList(this.productId).then((tagList) => {
              const tagArrList = tagList.data.map((item, index) => ({
                ...item,
                isSet: false,
              }));
              this.setState({ tagArrList });
              const tagsMap = fromEntries(tagList.data.map(tag => [tag.id, tag]));
              for (const item of Object.values(contentTagList.data)) {
                item.tags = Object.values(item.tags).map(id => tagsMap[id]);
              }
              resData.tags = contentTagList.data[resData.id].tags;
              resolve(resData);
            });
          } else {
            getProductsTagsList(this.productId).then((tagList) => {
              const tagArrList = tagList.data.map((item, index) => ({
                ...item,
                isSet: false,
              }));
              this.setState({ tagArrList });
              resolve(resData);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          resolve(resData);
        });
    });
  }

  renderWeappCaptcha() {
    return null;
  }

  verifyWeappCaptcha() {
    return Promise.resolve({});
  }

  // 回复并取消隐藏的初始值 默认为tru。
  initStateForCheckedDisabledHidden() {
    // NOTE: QQ产品希望回复时默认不取消隐藏，所以整些hard code
    const isQQ = [
      QQ_PRODUCT_ID,
      QQ_ANDROID_PRODUCT_ID,
      QQ_IOS_PRODUCT_ID,
    ].includes(Number(this.productId));
    if (isQQ) {
      return false;
    }
    // 默认值为 true
    let value = true;
    const res = JSON.parse(localStorage.getItem('checked_disabled_hidden'));

    if (typeof res !== 'undefined' && res !== null) {
      value = res;
    }

    return value;
  }

  /**
   * 上传回复的图片
   * @param data          带图片的表单
   * @param onProgress    更新上传进度的函数
   * */
  onUploadImage(data, onProgress) {
    return postApiPostsUploadImages({
      productId: this.productId,
      data,
      onProgress,
    });
  }

  /**
   * 发送回复内容
   * @param value     回复的文案
   * @param images    回复的图片
   * @return {Promise}
   * */
  onSubmit(value, images = []) {
    const { state, productId, postId } = this;

    // 帖子内容不能少于5个字
    if (value.length === 0) {
      const err = new Error('帖子内容不能为空');
      this.showToast(err);
      return Promise.reject(new Error(err));
    }

    let parentReplyId = null;

    if (state.action_id !== postId) {
      parentReplyId = state.action_id;
    }

    const params = {
      productId,
      postId,
      text: value,
      images,
      parent_reply_id: parentReplyId,
    };

    const promiseUpdateReplies = (res) => {
      this.commentAction(COMMENT_ACTIONS.CREATE, res.data.latest_reply_id);
      this.showToast('success');
      return res;
    };

    // 管理员，帖子被隐藏了，管理员又选择了回复关闭隐藏，则帮管理员公开帖子
    const promisePublicPost = (resolved) => {
      if (
        !state.sessionUser.is_admin
        || !state.postData.is_hidden
        || !state.checked_disabled_hidden
      ) {
        return resolved;
      }

      return postApiV2Posts({
        productId,
        postId,
        opt: 'hide',
        val: 'off',
        scene: 'post',
      }).then((resp) => {
        this.setState({ postData: resp.data });
      });
    };

    const promiseCaptcha = () => new Promise((resolve, reject) => {
      if (
        !state.sessionUser.is_system
        && (state.sessionUser.is_admin || !state.productData.is_slider_captcha_enabled)
      ) {
        this.hideMessageBar();
        this.showToast('submitting', true);
        resolve(params);
        return;
      }

      if (process.env.TARO_ENV === 'weapp') {
        // 小程序不走web验证码逻辑
        this.verifyWeappCaptcha().then(({ randstr, ticket }) => {
          params.captcha_randstr = randstr;
          params.captcha_ticket = ticket;
          this.hideMessageBar();
          this.showToast('submitting', true);
          resolve(params);
        });
        return;
      }

      if (!this.dynamicImportVercodeScript) {
        this.dynamicImportVercodeScript = importVercodeScript();
      }

      this.dynamicImportVercodeScript
        .then(() => {
          verificationCode('captcha_post').then(({ randstr, ticket }) => {
            params.captcha_randstr = randstr;
            params.captcha_ticket = ticket;
            this.hideMessageBar();
            this.showToast('submitting', true);
            resolve(params);
          });
        })
        .catch(() => {
          reject(new Error('验证码加载失败'));
        });
    });

    // 消息回复订阅 如果在第三方就
    const promiseNoticeUpdate = res => new Promise((resolved) => {
      this.notice.current
          && this.notice.current.subscribeReply(res.data.latest_reply_id);
      resolved(res);
    });

    return (
      promiseCaptcha()
        // 提交发帖
        .then(postApiPostsReplies)
        // 错误结果处理
        .then(processApiError)
        // 订阅回复
        .then(promiseNoticeUpdate)
        // 更新评论
        .then(promiseUpdateReplies)
        .then(promisePublicPost)
        .catch(error => this.showToast(error))
    );
  }

  /**
   * 显示输入框
   * @param isOpenEmoji     是否开启表情键盘
   * @param actionid         引用回复的 ID
   * @param replyName        回复者的姓名
   * */
  showMessageBar(isOpenEmoji = false, actionid, replyName) {
    this.saveAction().then(() => {
      const { state } = this;

      // 如果帖子被锁定，则不显示输入框
      if (state.postData.is_locked) {
        return;
      }

      this.setState({
        is_active_input: isOpenEmoji ? 2 : 1,
        action_id: actionid || this.postId,
        reply_name: replyName || state.postData.nick_name,
      });
    });
  }

  /**
   * 更新modul中帖子数据。目前只有小程序使用
   * @param postData 更新后帖子数据
   * @param opt      更新操作名称
   * @param val      更新操作值
   */
  handlePostUpdate() {}

  /**
   * 隐藏输入框
   * */
  hideMessageBar() {
    this.setState({ is_active_input: 0 });
  }

  /**
   * 显示全局提示
   * @param text              提示类型，如果是错误，直接传入 Error 对象
   * @param DO_NOT_AUTO_HIDE  是否阻止自动隐藏
   */
  showToast(text, DO_NOT_AUTO_HIDE = false) {
    this.setState({ toast: text });

    if (!DO_NOT_AUTO_HIDE) {
      setTimeout(() => this.hideToast(), 1500);
    }
  }

  /**
   * 隐藏全局提示
   * */
  hideToast() {
    this.setState({ toast: '' });
  }

  /**
   * 提交点赞
   */
  onClickLikeBtn() {
    const { productId, postId, state } = this;

    const preValue = Boolean(state.liked_list[postId] && state.liked_list[postId].liked);

    this.setState({
      liked_list: Object.assign({}, state.liked_list, {
        [postId]: { liked: !preValue },
      }),
    });

    postApiPostsLiked({
      productId,
      postId,
      val: preValue ? 'off' : 'on',
    }).then((resp) => {
      console.log('resp', resp);
      // 更新点赞数量
      this.setState(preState => ({
        postData: Object.assign({}, preState.postData, {
          like_count: resp.data.like_count,
          like_list: resp.data.like_list,
        }),
      }));
    });
  }

  /**
   * 显示\隐藏打赏模块
   */
  toggleTipped() {
    this.setState(preState => ({ show_tipped: !preState.show_tipped }));
  }

  /**
   * 显示\隐藏标签模块
   */
  toggleTag() {
    this.setState(preState => ({ showtag: !preState.showtag }));
  }

  /**
   * 打赏成功
   */
  onTippedSuccess() {
    const { productId, postId } = this;

    this.setState({ show_tipped: false });

    this.showToast('tippedSuccess');

    // 更新已打赏的用户信息
    postApiTipBatch({ productId, postId }).then((res) => {
      const tipBatch = res.data[postId].data;
      this.setState({ tipBatch });
    });
  }

  /**
   * 微信红包打赏
   * @param amount
   */
  zanShang(amount) {
    const params = {
      productId: this.productId,
      postId: this.postId,
      amount: Number(amount * 100).toFixed(0),
    };

    postApiTip(params)
      .then(processApiError)
      .then(resp => this.toggleTipped())
      .catch(error => this.showToast(error));
  }

  /**
   * 操作帖子
   * @param opt   操作对象，top/spam/hide/delete/report
   * @param val   操作的结果，on/off
   * */
  operationPosts({ opt, val }) {
    const { state } = this;

    if (opt === 'tag') {
      this.setState({
        showmore: false,
        showtag: true,
      });
      return;
    }
    // 是否对评论进行操作
    const isReply = Boolean(Number(state.action_id) !== Number(this.postId));

    if (!isReply) {
      // 主帖的操作

      postApiV2Posts({
        productId: this.productId,
        postId: this.postId,
        opt,
        val,
        scene: 'post',
      })
        .then(processApiError)
        .then(async (resp) => {
          /** 有变更,更新值 */
          const respData = await this.postListTagsAllGetApi(resp.data);
          this.setState(
            {
              postData: { ...respData, replies: null, replies_all: null },
              showmore: false,
            },
            () => {
              this.showToast(`${val}${opt}`);
            },
          );
          return { postData: respData, opt, val };
        })
        .then(this.handlePostUpdate)
        .catch(error => this.showToast(error));
    } else {
      // 评论的操作
      // 评论接口的操作指令，与主帖的操作指令不一致，需要做一次指令映射
      const repliesOptMap = {
        onhide: 'hide',
        offhide: 'show',
        onspam: 'mark_as_spam',
        offspam: 'undo_mark_as_spam',
      };

      const repliesOpt = repliesOptMap[`${val}${opt}`];
      if (opt === 'block') {
        return postApiV2PostsReplies({
          productId: this.productId,
          postId: this.postId,
          replyId: state.action_id,
          opt,
          val,
        })
          .then((resp) => {
            this.setState({ showmore: false });

            let toastText = `${val}${opt}`;

            if (opt === 'hide' && val === 'on') {
              toastText = `${toastText}reply`;
            }

            this.showToast(toastText);

            return resp;
          })
          .then((res) => {
            if (val === 'on') {
              this.commentAction(COMMENT_ACTIONS.BLOCK, res.data.id);
            }
          });
      }
      if (!repliesOpt) {
        console.log(`不合法的操作评论操作，opt:${opt} val:${val}`);
        return;
      }

      postApiReplies({
        productId: this.productId,
        replyId: state.action_id,
        opt: repliesOpt,
      })
        .then(processApiError)
        .then((resp) => {
          this.setState({ showmore: false });

          let toastText = `${val}${opt}`;

          if (opt === 'hide' && val === 'on') {
            toastText = `${toastText}reply`;
          }

          this.showToast(toastText);

          return resp;
        });
    }
  }

  /**
   * 删除帖子
   * */
  deletePosts() {
    const { state } = this;

    // 是否对评论进行操作
    const isReply = Boolean(Number(state.action_id) !== Number(this.postId));

    if (!isReply) {
      // 删除主帖
      deleteApiPosts({ productId: this.productId, postId: state.action_id })
        .then(processApiError)
        .then(() => this.handlePostUpdate({ postData: { id: this.postId }, opt: 'del' }))
        .then((resp) => {
          this.showToast('ondelete');

          // 删除成功后，后退到上一级页面，或者返回产品首页
          this.navigatorBack();
        })
        .catch(error => this.showToast(error));
    } else {
      const replyId = state.action_id;

      // 删除回复
      deleteApiPostsReplies({
        productId: this.productId,
        postId: this.postId,
        replyId,
      })
        .then(processApiError)
        .then(() => {
          this.showToast('ondelete');
          this.setState({ showmore: false });
          this.commentAction(COMMENT_ACTIONS.DELETED, replyId);
        })
        .catch(error => this.showToast(error));
    }
  }

  /**
   * 举报帖子
   * */
  reportPosts(reason) {
    const { state } = this;

    // 是否对评论进行操作
    const isReply = Boolean(Number(state.action_id) !== Number(this.postId));

    postApiAbuseReports({
      productId: this.productId,
      object_id: state.action_id,
      comment: reason,
      object_type: isReply ? 'reply' : 'post',
    })
      .then((res) => {
        this.setState({ showmore: false });
        this.showToast('report');
      })
      .catch((err) => {
        this.setState({ showmore: false });
        this.showToast(err);
      });
  }

  /**
   * 显示隐藏额外操作区域
   * */
  toggleOperationPosts(morePostData, actionId) {
    this.saveAction().then(() => {
      // 操作指定帖子
      if (morePostData && actionId) {
        this.setState({
          showmore: true,
          more_postData: morePostData,
          action_id: actionId,
        });
        return;
      }
      this.setState(preState => ({ showmore: !preState.showmore }));
    });
  }

  toggleDisabledHidden() {
    this.setState((preState) => {
      const value = !preState.checked_disabled_hidden;
      localStorage.setItem('checked_disabled_hidden', JSON.stringify(value));
      return { checked_disabled_hidden: value };
    });
  }

  /**
   * 返回上一页，或者打开产品首页
   * */
  navigatorBack() {
    if (document.referrer) {
      const url = new URL(document.referrer);

      /* 避免 bfcache，删除帖子后，不能直接返回上一页，而应该跳转去上一页 */
      window.location.href = url.pathname;
    } else {
      window.location.href = hrefHome();
    }
  }

  getLastCategories() {
    const { state } = this;
    return (
      state.postData.categories
      && state.postData.categories[state.postData.categories.length - 1]
    );
  }

  renderWantLike(isLiked) {
    const { postData } = this.state;
    const isIwant = postData.type === POST_TYPE.I_WANT;
    if (!isIwant) return null;
    return (
      <SupportBtn
        isLiked={isLiked}
        count={postData.like_count || 0}
        onClick={this.onClickLikeBtn}
        type={SUPPORT_BTN_TYPE.MOBILE_DETAIL}
      />
    );
  }

  renderDetail() {
    const { postData, tagArrList, showTopic } = this.state;
    const isIwant = postData.type === POST_TYPE.I_WANT;
    return isIwant ? (
      <WantDetailCard {...postData} showTopic={showTopic} renderLink={this.state.productData.ugc_url_status} />
    ) : (
      <FeedContent
        {...postData}
        tagArrList={tagArrList}
        showTopic={showTopic}
        renderLink={this.state.productData.ugc_url_status}
      />
    );
  }

  // 标签返回值
  changePostsList(e) {
    const postData = Object.assign({}, this.state.postData, { tags: e });
    this.setState({ postData });
  }

  saveAction = () => loginChecker.check().then((res) => {
    this.setState({ sessionUser: res.data });
  });

  commentAction = (commentActionType, commentActionId) => {
    this.setState({ commentActionType, commentActionId });
  };

  render() {
    const { state } = this;
    const { commentActionId, commentActionType } = this.state;
    // 当前用户是否发帖用户
    const isOwner =      Boolean(state.more_postData.fromUser?.id === state.sessionUser.user_id
          || state.more_postData.user_id === state.sessionUser.user_id) && state.sessionUser.is_believable;
    // 当前操作的是主帖还是回复
    const isReply = Boolean(Number(state.action_id) !== Number(this.postId));
    // 主帖的点赞状态
    const isLikedForMainPost = Boolean(state.liked_list[this.postId] && state.liked_list[this.postId].liked);
    // 获取最后一级分类
    const lastCategories = this.getLastCategories();
    // 支持微信支付
    const supportWxPay = isMoreThenMicroMessenger5();
    // 底部fixedComponent 组合
    const footerCompose = () => (
      <FooterContainer
        button={<GoHome homeUrl={hrefHome()} />}
        bar={
          <FooterEnter
            showMessageBar={this.showMessageBar}
            is_locked={state.postData.is_locked}
            showMessageEmoji={() => {
              this.showMessageBar(true);
            }}
          />
        }
      />
    );
    const tipTotal = (
      state.tipBatch.reduce((acc, item) => acc + item.amount, 0) / 100
    ).toFixed(2);
    const canReward =      state.productData.tip_status === 'on'
      && supportWxPay
      && state.can_be_tipped
      // 社区暂时不开放打赏
      && !this.communityId;
    const hasPostType = state.postData.type !== undefined;
    const isIwant = state.postData.type === POST_TYPE.I_WANT;
    return (
      <BasePage
        footer={footerCompose()}
        canLayoutScroll={!state.is_active_input && !state.showmore}
        className="emebed-post"
        setProductInfo={this.setProductInfo}
        setUserInfo={this.setUserInfo}
      >
        <Notification ref={this.notice} />
        <ErrorBoundary error_type={state.error_info}>
          <Tag.div className="page_post page">
            <Tag.div className="page_post_feedDetail">
              {this.renderDetail()}
              {state.tipBatch.length > 0
                && state.productData.tip_status === 'on' && (
                <Tag.div className="user_apprec">
                  {state.tipBatch.map((item, index) => (
                    <Tag.img
                      className="user_pic"
                      src={item.avatar}
                      key={index}
                    />
                  ))}
                  <Tag.span className="apprec_time">
                    {state.tipBatch.length}人赞赏 共{tipTotal}元
                  </Tag.span>
                </Tag.div>
              )}
              <Tag.div className="feed_operate">
                {this.renderWantLike(isLikedForMainPost)}
                <Tag.div className="operate_font">
                  {lastCategories && `#${lastCategories.value}#`}
                </Tag.div>
                <Tag.div className="operate_btn">
                  {/* 点赞 */}
                  {hasPostType && !isIwant && (
                    <Tag.div
                      className="btn-item area_likes"
                      onClick={event => this.onClickLikeBtn(event)}
                      {...ariaRole('button')}
                      tabindex="0"
                      aria-label={`${state.postData.like_count}赞  ${
                        isLikedForMainPost ? '已点赞' : ''
                      }`}
                    >
                      {isLikedForMainPost ? (
                        <Icon className="tcIcon_liked" type="verygood-line" />
                      ) : (
                        <Icon className="tcIcon_likes" type="good-line" />
                      )}

                      {state.postData.like_count > 0 && (
                        <Tag.span
                          className={classNames('area_num ', {
                            active: isLikedForMainPost,
                          })}
                        >
                          {state.postData.like_count}
                        </Tag.span>
                      )}
                    </Tag.div>
                  )}
                  {/* 打赏 */}
                  {canReward && (
                    <Tag.div
                      className="btn-item"
                      onClick={event => this.toggleTipped()}
                      {...ariaRole('button')}
                      aria-label="赞赏"
                      needAuth
                    >
                      <TcIconReward />
                    </Tag.div>
                  )}
                  <Tag.button
                    className="btn-item"
                    onClick={event => this.toggleOperationPosts(state.postData, this.postId)
                    }
                    needAuth
                    {...ariaRole('button')}
                    aria-label="更多操作"
                  >
                    <TcIconMore />
                  </Tag.button>
                </Tag.div>
              </Tag.div>
            </Tag.div>
            <PostCommentList
              postId={this.postId}
              productId={this.productId}
              authorId={state.postData.user_id}
              action={commentActionType}
              actionComentId={commentActionId}
              onClickItem={(id, name) => this.showMessageBar(false, id, name)}
              onClickMore={evt => this.toggleOperationPosts(evt, evt.id)}
              renderLink={state.productData.ugc_url_status}
            />
          </Tag.div>

          {/* 输入区域 */}
          <MessageBar
            active={state.is_active_input}
            placeholder={
              isReply ? `回复 ${state.reply_name}` : '走心的评论更容易被看到哦'
            }
            Footer={
              state.sessionUser.is_admin && state.postData.is_hidden ? (
                <Tag.div className="message-bar-footer">
                  <BtnPublic
                    checked={state.checked_disabled_hidden}
                    onChange={this.toggleDisabledHidden}
                  />
                </Tag.div>
              ) : null
            }
            onClose={event => this.hideMessageBar()}
            onUploadImage={this.onUploadImage}
            onSubmit={this.onSubmit}
            onError={error => this.showToast(error)}
          />

          {/* 打赏模块 */}
          <TippedModal
            avatar_url={state.postData.avatar_url}
            nick_name={state.postData.nick_name}
            isActive={state.show_tipped}
            productId={this.productId}
            postId={this.postId}
            onCancel={() => this.toggleTipped()}
            onSuccess={() => this.onTippedSuccess()}
            onError={error => this.showToast(error)}
          />

          {/* 更多操作组件弹层 */}
          <MoreOperations
            showmore={state.showmore}
            onChange={res => this.operationPosts(res)}
            onCancel={res => this.toggleOperationPosts()}
            onDelete={event => this.deletePosts()}
            onReport={reason => this.reportPosts(reason)}
            is_admin={state.sessionUser.is_admin}
            is_admin_post={
              state.more_postData.is_admin || state.more_postData.isAdmin
            }
            is_owner={isOwner}
            is_top={state.more_postData.is_top}
            is_hidden={state.more_postData.is_hidden}
            is_locked={state.more_postData.is_locked}
            is_spam={state.more_postData.is_spam}
            is_good={state.more_postData.is_good}
            is_reply={isReply}
            nick_name={
              state.more_postData.nick_name
              || state.more_postData?.fromUser?.userName
            }
            tags={state.more_postData.tags}
          />
          {/* 标签操作组件弹层 */}
          {state.showtag && (
            <TagMobileChoose
              showtag={state.showtag}
              postId={this.postId}
              productId={this.productId}
              onCancel={res => this.toggleTag()}
              onError={error => this.showToast(error)}
              changePostsList={this.changePostsList.bind(this)}
            />
          )}
          {/* 小程序验证码 */}
          {this.renderWeappCaptcha()}
          {/* 提示内容 */}
          <GlobalToast type={state.toast} />
        </ErrorBoundary>
      </BasePage>
    );
  }
}

/**
 * 接口错误的统一处理
 * @param resp
 * @return {Promise<never>|*}
 */
export const processApiError = function (resp) {
  // 如果失败，显示失败的内容
  if (resp.status !== 0) {
    return Promise.reject(new Error(resp.message));
  }

  return resp;
};

export { App };
