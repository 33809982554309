/* eslint-disable react/prop-types, camelcase */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Tag from 'components/tag';
import RichText from 'components/rich-text';
import { href_profile, ceGroupName } from 'components/href-helper';
import { ariaRole } from 'components/util/aria';
import { LazyImage } from 'components/lazy-image';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';
import { TAG_COLOR } from 'components/tag-show/constants';
import { ImageMobile } from 'components/comment-image';
import { getProductId, getContentId, getChangeLogContent } from 'components/util';
import Topic from 'components/topic/topic-container';
import defaultImg from 'components/assets-image/user/default-avatar.png';
import goodIssue from 'components/assets-image/common-icon/good_issue.png';
import { POST_TYPE } from '../../../components/constants/app';
import { EmbedChangeLog } from '../../../components/change-log-embed';

const FeedContent = (props) => {
  const [userAvatar, setUserAvatar] = useState(props.avatar_url);
  // 获取内容读屏
  const getContentAria = () => {
    const { is_top, is_hidden, is_spam, is_notice, is_good, content, time } = props;
    const top = is_top ? '置顶' : '';
    const hidden = is_hidden ? '隐藏' : '';
    const spam = is_spam ? '黑名单' : '';
    const notice = is_notice ? '公告' : '';
    const good = is_good ? '好问题' : '';
    return `${top} ${hidden} ${spam} ${notice} ${good} ${content} ${time}`;
  };
  const productId = getProductId();
  const postId = getContentId();

  function loadImageError() {
    setUserAvatar(defaultImg);
  }

  useEffect(() => {
    setUserAvatar(props.avatar_url);
  }, [props.avatar_url]);

  function styleyClass(item) {
    const selectColor = TAG_COLOR.find(i => i.color === item.setting.color) || {};
    return {
      backgroundColor: selectColor.bd || '#eee',
      color: '#fff',
      border: 'none',
      wordBreak: 'keep-all',
    };
  }

  function renderContent() {
    if (props.type === POST_TYPE.CHANGE_LOG) {
      const { content: postContent } = props;
      const changeLog = getChangeLogContent(postContent);
      // bloomFilterInsert(props.id);
      changeLog.id = props.id;
      changeLog.date = props.created_at_timestamp;
      return <EmbedChangeLog changeLog={changeLog} isDetail={true} productId={props.productId} />;
    }
    return <Tag.div className="feed_desc" >
      <RichText
        className="feed_desc_font"
        content={dangerouslySetInnerHTML(props.content, '', 'br', props.renderLink === 'on' || props.is_admin).__html}
        aria-label={getContentAria()}
        {...ariaRole('option')}
      ></RichText>
      {(props.images && (props.images.length > 0))
        && <ImageMobile imgList={props.images} />
      }
    </Tag.div>;
  }

  return (
    <Tag.div className="feed_content">
      <Tag.div className="feed_user">
        <Tag.a className="feed_user_left" href={href_profile(props.user_id)} >
          <LazyImage aria-hidden={true} src={userAvatar} onError={loadImageError} className="user_pic" />
          <Tag.div className="user_infor">
            <Tag.div className={classNames('user_infor_name auto-focus', { admin: props.is_admin })}>{props.nick_name}</Tag.div>
            <Tag.div className="user_infor_cont" aria-hidden={true}>
              {props.time} {ceGroupName(props.group_name, props.postname)}
            </Tag.div>
          </Tag.div>
        </Tag.a>
        <Tag.div className="feed_user_right" aria-hidden={true}>
          {props.tags && props.tags.map((item, i) => (
            <Tag.span className="user_label label_tag" key={`${i}`}
              style={styleyClass(item)}>{item.name}</Tag.span>
          ))}
          {props.is_spam && <Tag.span className="user_label">黑名单</Tag.span>}
          {props.is_notice && <Tag.span className="user_label">公告</Tag.span>}
          {props.is_good && <Tag.span className="user_label label_good"><Tag.img className="feed_content__good" src={goodIssue} alt="好问题" /></Tag.span>}
          {props.is_hidden && <Tag.span className="user_label">隐藏</Tag.span>}
          {props.is_top && <Tag.span className="user_label">置顶</Tag.span>}
        </Tag.div>
      </Tag.div>
      {props.showTopic && <Tag.div className="topic_title">
        {<Topic productId={productId} postId={postId} isProduct />}
      </Tag.div>}
      {renderContent()}
    </Tag.div >
  );
};

export { FeedContent };
