import React from 'react';
import Tag from 'components/tag';
import { TcIconGoHomeDark } from 'components/IconSystem';
import PropTypes from 'prop-types';
import './style.less';

const GoHome = props => (
  <Tag.a className="back_home" href={props.homeUrl} aria-label="返回首页">
    <TcIconGoHomeDark className="tcIcon tcIcon_go_home" />
  </Tag.a>
);



GoHome.defaultProps = {
  homeUrl: 'https://txc.qq.com',
};
GoHome.propTypes = {
  homeUrl: PropTypes.string,
};
export { GoHome };
